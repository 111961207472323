import './App.css';
import { ParticlesComponent } from './Particles';

function App() {
  return (
    <div className="App">
      <ParticlesComponent />
      <header className="landing">
        <div className="logo">
          <img src="./assets/HE-Logo-417x128.png" alt="Logo" />
        </div>
      </header>
      <section className="landing">
      </section>
    </div>
  );
}

export default App;
